import React from "react";
import { graphql, StaticQueryDocument } from "gatsby";

import Layout from "../components/layout";

type BlogArticleTemplateProps = {
  data: {
    markdownRemark: {
      frontmatter: { date: string; title: string };
      html: string;
    };
  };
};

export default function BlogArticleTemplate({
  data,
}: BlogArticleTemplateProps): JSX.Element {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout>
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </Layout>
  );
}

export const pageQuery: StaticQueryDocument = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        title
      }
      fields {
        slug
      }
    }
  }
`;
